import React from 'react';
import ReactDOM from 'react-dom';


import TechPanel from './pages/techpanel';
import BlogPanel from './pages/blogpanel';
import MusicPanel from './pages/musicpanel';
import SocialPanel from './pages/socialpanel';
import AboutPanel from './pages/aboutpanel';
import SplashPage from './pages/splashpage';

import "tailwindcss/tailwind.css"

const sass = require('sass');

const splashContainer = document.querySelector('.splashpage');
const subpageContainer = document.querySelector('#subpage-container');
let menuToggle = null;

const links = {
    github: "https://github.com/selassie-eye",
    linkedin: "https://www.linkedin.com/in/khari-ollivierre/",
    soundcloud: "https://soundcloud.com/selassie-oeil",
    twitter: "https://twitter.com/Selassie_Eye"
}

function getMenuToggle(element) {
    menuToggle = element
}

ReactDOM.render(React.createElement(SplashPage, {unmountCallback: unmountPanel, menuToggleCallback: getMenuToggle, links: links}), splashContainer); 

function unmountPanel() {
    ReactDOM.unmountComponentAtNode(subpageContainer)
}

function mountPanel(el) {
    menuToggle.checked = false;
    ReactDOM.render(React.createElement(el, {unmountCallback: unmountPanel}), subpageContainer); 
}

window.mountTechPanel = () => {
    mountPanel(TechPanel);
}

window.mountMusicPanel = () => {
    mountPanel(MusicPanel);
}

window.mountSocialPanel = () => {
    mountPanel(SocialPanel);
}

window.mountBlogPanel = () => {
    mountPanel(BlogPanel);
}

window.mountAboutPanel = () => {
    mountPanel(AboutPanel);
}
