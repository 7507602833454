import React, { Component } from "react";

import ContactForm from "../components/contactform";

import "../sass/_about.scss"

class AboutPanel extends Component {
    render() {
        return (
        <div class="u-page-padding about">
            <div class="body">
                <div class="">
                    <div class="">
                        <div class="about__close">
                            <span onClick={this.props.unmountCallback}>X</span>
                        </div>
                        <div class="about__resume">
                            <a href="static/resume.pdf">resume &darr;</a>
                        </div>
                        <div class="about__intro about__section">
                            <h2>Hello there!</h2>
                            <p>I’m Khari Ollivierre, a technologist with an interest in the fundamentals of computing and how we use technology to effect our lives and the world around us.</p>
                            <p>I empower people and businesses with the tools of the modern age (AI, cloud computing, and digital security) through education, accessibility and full-stack system support.</p>
                            <p>My mission is to break open the black box of advanced technology by removing prohibitive cost and knowledge barriers so that the whole world can leverage humanity's collective achievements.</p>
                            <p>This website is the landing page for my digital life and a way to connect with the world in my own way. Here you'll find details about my projects, my writing and my music. If you're interested in working together, you can reach me through the various channels available here. Thank you for visiting!</p>
                        </div>
                        <div class="about__contact about__section hidden"> <h2>Contact Us</h2>
                            <p>If you would like to talk more, please use the form below to send an email or reach out to me on any of my social platforms. Talk soon!</p>
                            <div class="about__contact--row">
                                <h3>Find me here:</h3>
                                <div class="icon-row">
                                    <a href="https://www.linkedin.com/in/khari-ollivierre/"><span class="iconify inline-block m-3" data-icon="ant-design:linkedin-filled" data-width="32" data-height="32"></span></a>
                                    <a href="https://github.com/selassie-eye"><span class="iconify inline-block m-3" data-icon="akar-icons:github-fill" data-width="32" data-height="32"></span></a>
                                    <span class="iconify inline-block m-3" data-icon="cib:indeed" data-width="32" data-height="32" ></span>
                                    <a href="https://www.upwork.com/freelancers/~01acf268aa2eaca632"><span class="iconify inline-block m-3" data-icon="cib:upwork" data-width="32" data-height="32" ></span></a>
                                </div>
                            </div>
                            <div class="about__contact--row">
                                <h3>And here:</h3>
                                <div class="icon-row">
                                    <a href="https://twitter.com/Selassie_Eye"><span class="iconify inline-block m-3" data-icon="ant-design:twitter-outlined" data-width="32" data-height="32"></span></a>
                                    <a href="https://www.twitch.tv/selassie_eye"><span class="iconify inline-block m-3" data-icon="akar-icons:twitch-fill" data-width="32" data-height="32"></span></a>
                                    <a href="https://www.youtube.com/channel/UCiMnrlTZUJpnRR7Js4ry2Nw"><span class="iconify inline-block m-3" data-icon="ant-design:youtube-filled" data-width="32" data-height="32"></span></a>
                                    <a href="https://www.instagram.com/selassie_eye/"><span class="iconify inline-block m-3" data-icon="ant-design:instagram-filled" data-width="32" data-height="32"></span></a>
                                    <a href="https://soundcloud.com/selassie-oeil"><span class="iconify inline-block m-3" data-icon="akar-icons:soundcloud-fill" data-width="32" data-height="32"></span></a>
                                </div>
                            </div>
                            {/* <div class="about__contact--row">
                                <ContactForm />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default AboutPanel;
