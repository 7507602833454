import React, {Component} from "react";
import ReactDOM from 'react-dom';

import AboutPanel from './aboutpanel';

import MainMenu from "../components/mainmenu";

import "../sass/splash.scss";

class SplashPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuToggle: false,
            pageOpen: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.mountPanel = this.mountPanel.bind(this);
        this.unmountPanel = this.unmountPanel.bind(this);
    }
    
    toggleMenu() {
        this.setState(prev => ({menuToggle: !prev.menuToggle}));
    }

    mountPanel(el) {
        this.setState({menuToggle: false});
        this.setState({pageOpen: true});
        ReactDOM.render(React.createElement(el, {unmountCallback: this.unmountPanel}), document.querySelector('#subpage-container')); 
    }

    unmountPanel() {
        this.setState({pageOpen: false});
        ReactDOM.unmountComponentAtNode(document.querySelector('#subpage-container'))
    }

    render() {
        return(
        <section className="splash">
        <div className="bg-vid">
            <video className="bg-vid__content" autoPlay muted loop>
                <source src="img/bgvid.mp4" type="video/mp4" />
                <source src="img/bgvid.webm" type="video/webm"/>
                    Video not supported
            </video>
        </div>
        <MainMenu aboutCallback={() => this.mountPanel(AboutPanel)} menuToggle={this.state.menuToggle} toggleMenuCallback={this.toggleMenu} links={this.props.links} pageOpen={this.state.pageOpen}/>
        <div className={this.state.pageOpen ? "title hidden" : "title"} onClick={() => this.mountPanel(AboutPanel)}>
            <h1 className="title__main">Khari Ollivierre</h1>
            <h3 className="title__sub">Building a Better Future Together</h3>
        </div>
        <div id="subpage-container"></div>
        </section>
        );
    }
}

export default SplashPage;
